<script>
export default {
  name: 'About',
  page: {
    title: 'Περιγραφή'
  }
}
</script>

<template>
  <div>
    <div class="page-title">
      <b-container class="bv-example-row">
        <h1 class="title">Περιγραφή</h1>
      </b-container>
    </div>
    <div class="page-body">
      <b-container class="bv-example-row">
        <p class="page-text">
          To έργο ΕΥΑΠΟΔΡΑΣΗ έχει ως αντικείμενο το σχεδιασμό και την ανάπτυξη
          εκπαιδευτικών δραστηριοτήτων με παιγνιώδη (gamification)
          χαρακτηριστικά, χωρική ευελιξία, ψηφιακό υπόβαθρο, «φορμαλισμό» που
          προέρχεται από τα δωμάτια απόδρασης (escape rooms), κεντρικό άξονα τον
          πολιτισμό και διαθεματική προσέγγιση που βασίζεται στο STEAM.
        </p>
        <p class="page-text">
          Η συγκεκριμένη προσέγγιση θα προσπαθήσει να απαλλάξει τις
          εκπαιδευτικές δραστηριότητες από τη «σοβαροφάνεια» που χαρακτηρίζει
          αντίστοιχες δραστηριότητες που βασίζονται στο STEAM, αξιοποιώντας τη
          δυναμική των δωματίων απόδρασης (escape rooms) προσδίδοντας παράλληλα
          στα τελευταία εκπαιδευτική «χροιά».
        </p>
        <p class="page-text">
          Υπό το πρίσμα των παραπάνω στο πλαίσιο του έργου έχουν δημιουργηθεί
          εκπαιδευτικές δραστηριότητες απόδρασης, οι οποίες απευθύνονται στην
          ηλικιακή ομάδα 9-15 και λειτουργούν σε δύο διαφορετικές προσεγγίσεις,
          απευθυνόμενες σε δύο διαφορετικές ομάδες πληθυσμού στόχου.
        </p>
        <p class="page-text">
          Δραστηριότητες που λειτουργούν στο πλαίσιο συγκεκριμένων οργανωμένων
          χώρων που υλοποιούν «χώρους» απόδρασης αξιοποιώντας αλληλεπιδραστικές
          ψηφιακές διατάξεις προβολής, ηχητικές και φωτιστικές διατάξεις και
          κεντρικό έλεγχο, ενώ οι μαθητές χρήστες αλληλοεπιδρούν με το χώρο και
          τις διατάξεις με τη χρήση φορητών συσκευών, μέσω εφαρμογών που έχουν
          αναπτυχθεί για το σκοπό αυτό και αξιοποιούν μια σειρά από διατάξεις
          και τεχνικές (beacons, QR codes, Augmented Reality), ενώ παράλληλα
          είναι σε θέση να εκτελέσουν πειράματα αξιοποιώντας απλές διατάξεις.
        </p>
        <p class="page-text">
          Δραστηριότητες που είναι σε θέση να λειτουργήσουν σε χώρους πολιτισμού
          (Μουσεία, Μνημεία, κλπ.), σχολικές αίθουσες, παιδότοπους, αλλά και σε
          οικιακό περιβάλλον. Στο πλαίσιο των συγκεκριμένων χώρων οριοθετούνται
          με τη χρήση ασύρματων αισθητήρων εικονικά «δωμάτια», από τους οποίους
          η απομάκρυνση είναι αδύνατη χωρίς την ενεργοποίηση ηχητικού σήματος.
          Στη συγκεκριμένη προσέγγιση επιχειρείται η απαλλαγή των δραστηριοτήτων
          απόδρασης από τον χωρικό περιορισμό ενός συγκεκριμένου «δωματίου». Οι
          χρήστες αλληλοεπιδρούν με το «δωμάτιο» μέσω κινητών συσκευών οι οποίες
          είναι εφοδιασμένες με εφαρμογές και οι οποίες επιτρέπουν τόσο τον
          κεντρικό τους έλεγχο όσο και την ανάκτηση περιεχομένου μέσω κεντρικής
          cloud based πλατφόρμας. Οι συγκεκριμένες δραστηριότητες μπορεί να
          υποστηριχθούν και με την παρουσία εξειδικευμένου εμψυχωτή (animator).
        </p>
        <p class="page-text">
          Οι δραστηριότητες που αφορούν και τις δύο παραπάνω προσεγγίσεις
          βασίζονται σε εξειδικευμένα παιδαγωγικά σενάρια (storyboards), που
          έχουν ως άξονα τη θεματολογία που βασίζεται στον πολιτισμό και
          επιχειρούν ταυτόχρονα μια διαθεματική προσέγγιση που βασίζεται στα
          προγράμματα σπουδών που αφορούν τις συγκεκριμένες ηλικιακές ομάδες και
          αντλούν τις αρχές τους από το STEAM.
        </p>
        <p class="page-text">
          Αποτέλεσμα του έργου είναι η δημιουργία δύο διαφορετικών
          «οικοσυστημάτων» προϊόντων και υπηρεσιών, το πρώτο εκ των οποίων θα
          απευθύνεται σε επιχειρηματίες του χώρου της ψυχαγωγίας, οι οποίοι και
          αξιοποιώντας το είναι σε θέση να λειτουργήσουν εκπαιδευτικές
          δραστηριότητες απόδρασης με κεντρικό έλεγχο, διαρκή υποστήριξη και
          ευελιξία στην προσαρμογή της θεματολογίας. Το δεύτερο οικοσύστημα
          απευθύνεται σε χώρους που παρουσιάζουν τουριστικό και πολιτιστικό
          ενδιαφέρον, σε εκπαιδευτικούς οργανισμούς παιδότοπους και οικιακούς
          χρήστες, δίνοντάς τους τη δυνατότητα να δημιουργήσουν ad hoc μια
          δραστηριότητα απόδρασης σε χώρο που θα επιλέξουν εκείνοι και με
          θεματολογία που θα προσαρμόζεται στο αντικείμενό τους.
        </p>
        <p class="page-text">
          Η Υλοποίηση της Δράσης έγινε στην Περιφέρεια Αττικής με την κατανομή
          του Προϋπολογισμού και της Δημοσίας Δαπάνης ανά συμμετέχοντα φορέα να
          εμφανίζεται στον παρακάτω πίνακα:
        </p>
        <table class="about-table">
          <thead>
            <tr>
              <th class="about-table-first-row">ΦΟΡΕΑΣ</th>
              <th class="about-table-first-row">ΠΡΟΥΠΟΛΟΓΙΣΜΟΣ (€)</th>
              <th class="about-table-first-row">ΔΗΜΟΣΙΑ ΔΑΠΑΝΗ (€)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="about-table-second-row">BYTE A.B.E.E.</td>
              <td class="about-table-second-row">190.000,00</td>
              <td class="about-table-second-row">142.500,00</td>
            </tr>
            <tr>
              <td class="about-table-third-row">POBUCA SIEBEN IKE</td>
              <td class="about-table-third-row">160.000,00</td>
              <td class="about-table-third-row">120.000,00</td>
            </tr>
            <tr>
              <td class="about-table-fourth-row">ΣΥΝΟΛΟ:</td>
              <td class="about-table-fourth-row">350.000,00</td>
              <td class="about-table-fourth-row">262.500,00</td>
            </tr>
          </tbody>
        </table>
      </b-container>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "./../assets/scss/abstracts" as *;

.page-title {
  background-color: $white;
  padding: $s-xl 0 $s-s 0;
}

.title {
  color: $black;
  font-family: "Waiting for the Sunrise", cursive;
  padding: $s-s 0;
}

.page-body {
  background-color: $alto;
  color: $black;
  padding: $s-xl 0;
}

.page-text {
  line-height: $s-xl;
}
.about-table {
  width: 100%;

  .about-table,
  .about-table-first-row,
  .about-table-second-row,
  .about-table-third-row,
  .about-table-fourth-row {
    border: 1px solid;
    text-align: center;
  }

  .about-table-fourth-row {
    font-weight: bold;
  }
}
</style>
